import { Checkbox, FormControlLabel, InputLabel } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { CraftFormElement, CraftFormElementChoice } from '../../../interfaces/form.interface';

const RTCheckbox: FC<{
  control: Control<FieldValues>;
  question: CraftFormElement;
  onChange: (value: string[]) => void;
}> = ({ question, control }): any => {
  const [checkedValues, setCheckedValues] = useState<CraftFormElementChoice[]>();
  const [required, setRequired] = useState(true);

  const isChecked = (choice: CraftFormElementChoice): boolean => {
    let isChecked = false;
    checkedValues?.forEach(v => {
      if (v.value === choice.value) {
        isChecked = true;
      }
    });
    return isChecked;
  };

  useEffect(() => {
    if (checkedValues && checkedValues.length > 0) {
      setRequired(false);
    }
  }, [checkedValues]);

  return (
    <>
      <InputLabel>
        {question.title}
        {question.isRequired ? ' *' : ''}
      </InputLabel>
      <div style={{ maxWidth: '530px' }}>
        {question.choices.map(choice => (
          <FormControlLabel
            key={`cb-${choice.value}`}
            name={question.name}
            label={choice.text}
            control={
              <Controller
                name={question.name}
                control={control}
                defaultValue={[]}
                render={({ field: { onChange, onBlur, value } }: any) => (
                  <Checkbox value={isChecked(choice)} onChange={onChange} onBlur={onBlur} defaultValue={value} />
                )}
              />
            }
          />
        ))}
      </div>
    </>
  );
};

export default RTCheckbox;
