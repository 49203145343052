import MomentUtils from '@date-io/moment';
import { FormLabel } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { FC } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

import { CraftFormElement } from '../../../interfaces/form.interface';

const RTDate: FC<{
  control: Control<FieldValues>;
  question: CraftFormElement;
}> = ({ control, question }) => {
  return (
    <Controller
      control={control}
      name={question.name}
      defaultValue={new Date().toISOString().substring(0, 10)}
      render={({ field: { onChange, onBlur, value } }) => (
        <>
          <FormLabel>
            {question.title} {question.isRequired ? ' *' : ''}
          </FormLabel>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              required={question.isRequired === 'true'}
              disableToolbar
              variant='inline'
              format='YYYY-MM-DD'
              margin='normal'
              disablePast
              value={value}
              helperText={question.description}
              inputValue={value}
              onBlur={onBlur}
              onChange={e => {
                onChange(e?.format('YYYY-MM-DD'));
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </>
      )}
    />
  );
};

export default RTDate;
