import { FormControl, FormHelperText, FormLabel, Input } from '@material-ui/core';
import React, { FC } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { CraftFormElement } from '../../../interfaces/form.interface';

const RTText: FC<{
  control: Control<FieldValues>;
  question: CraftFormElement;
  error: any;
}> = ({ control, question, error }) => {
  return (
    <Controller
      control={control}
      name={question.name}
      error={!!error}
      helperText={error?.type}
      fullWidth
      render={({ field: { onChange, onBlur, value, ref } }: any) => (
        <FormControl>
          <FormLabel required={question.isRequired === 'true'} component='legend' style={{ marginBottom: 0 }}>
            {question.title}
          </FormLabel>
          <FormHelperText style={{ marginBottom: '1rem' }}>{question.description}</FormHelperText>
          <Input
            required={question.isRequired === 'true'}
            placeholder={question.title}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            type={question.validators && question.validators[0] ? question.validators[0].type : 'text'}
            style={{ marginBottom: '1rem' }}
          />
        </FormControl>
      )}
    />
  );
};

export default RTText;
