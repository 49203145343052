import React from 'react';
import { Control, DeepMap, FieldError, FieldValues } from 'react-hook-form';
import RTCheckbox from '../../components/form/inputs/checkbox';
import RTDate from '../../components/form/inputs/date';
import RTMultipleSelect from '../../components/form/inputs/multiple-select';
import RTRadio from '../../components/form/inputs/radio';
import RTSelect from '../../components/form/inputs/select';
import RTText from '../../components/form/inputs/text';
import { CraftFormElement } from '../../interfaces/form.interface';
import * as style from './form.module.scss';

export const renderInputs = (
  question: CraftFormElement,
  idx: number,
  setValue: (question: string, value: any, config?: any) => void,
  control: Control<FieldValues>,
  errors: DeepMap<FieldValues, FieldError>,
  value: any,
) => {
  switch (question.type) {
    case 'sectionHeader':
      return (
        <h2 className={style.sectionTitle} key={idx + question.name}>
          {question.title}
        </h2>
      );
    case 'dropdown':
      if (question.name === 'countries') {
        return <RTMultipleSelect question={question} error={errors[question.name]} key={'ms' + question.name} control={control} />;
      }
      return <RTSelect question={question} key={'s' + question.name} control={control} />;
    case 'radiogroup':
      return (
        <>
          <RTRadio question={question} key={'r' + question.name} control={control} value={value} />
        </>
      );

    case 'checkbox':
      return (
        <RTCheckbox
          question={question}
          key={question.name}
          control={control}
          onChange={e => {
            setTimeout(() => {
              setValue(question.name, e, {});
            });
          }}
        />
      );

    case 'date':
      return <RTDate question={question} key={'d' + question.name} control={control} />;

    case 'text':
      return <RTText question={question} control={control} error={errors[question.name]} key={'t' + question.name} />;

    default:
      break;
  }
};

export const renderTitle = (idx: number, question: any) => {
  return (
    <h2 className={style.sectionTitle} key={idx + question.name}>
      {question.title} {question.isRequired ? '*' : ''}
    </h2>
  );
};
