// extracted by mini-css-extract-plugin
export var broadcast = "form-module--broadcast--4kQhH";
export var broadcastButton = "form-module--broadcast-button--jVZeb";
export var buttonWrapper = "form-module--button-wrapper--auII0";
export var form = "form-module--form--sIQoh";
export var formContainer = "form-module--form-container--d5TgZ";
export var formDescription = "form-module--form-description--064Bp";
export var formTitle = "form-module--form-title--nQsah";
export var legend = "form-module--legend--J7ccD";
export var pager = "form-module--pager--IXY4g";
export var previousButton = "form-module--previous-button--O8Xml";
export var sectionTitle = "form-module--section-title--bznJw";
export var submitButton = "form-module--submit-button--lQxQ5";