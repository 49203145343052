import { FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { FC } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { CraftFormElement } from '../../../interfaces/form.interface';

const RTSelect: FC<{
  control: Control<FieldValues>;
  question: CraftFormElement;
}> = ({ control, question }) => (
  <Controller
    control={control}
    name={question.name}
    defaultValue={question.choices[0].value}
    render={({ field: { onChange, onBlur, value, ref } }: any) => {
      return (
        <div className='rt-input-select'>
          <InputLabel>
            {question.title} {question.isRequired ? ' *' : ''}
          </InputLabel>
          <FormHelperText style={{ marginBottom: '0.5rem' }}>{question.description}</FormHelperText>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            labelId={question.title}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            style={{ marginBottom: '1rem' }}
          >
            {question.choices.map((choice, idx) => (
              <MenuItem key={`${question.name}-${choice.value}-${idx}`} value={choice.value}>
                {choice.text}
              </MenuItem>
            ))}
          </Select>
        </div>
      );
    }}
  ></Controller>
);

export default RTSelect;
