import gql from 'graphql-tag';
import { CraftForm, CraftFormElement } from '../../interfaces/form.interface';
import {
  applicationIberiaItaly,
  applicationTurkey,
  applicationsCentralEurope,
  applicationsEasternEurope,
  applicationsFranceBenelux,
  applicationsMiddleEastNorthAfrica,
  applicationsRussiaCis,
  applicationsUkNordics,
} from './form.constant';
import { Region } from './type';

export const ADD_MUTATION = (functionName: string, form: CraftFormElement[]) => {
  const mutationVariables = form.map(element => `$${element.name}: ${element.type === 'dropdown' ? '[String]' : 'String'}`).join(', ');
  const mutationArguments = form.map(element => `${element.name}:$${element.name}`).join(', ');

  return gql`
    mutation(
      $title: String,
      $slug: String,
      $tournamentApplicationStatus: String,
      $tournamentApplicationGames: String,
	    $applicationRegion: String,
	    $applicationLanguage: String,
      ${mutationVariables}
	  ) {
		${functionName}(
			authorId: 1,
			title: $title,
			slug: $slug,
			applicationRegion: $applicationRegion,
			applicationLanguage: $applicationLanguage,
			tournamentApplicationStatus: $tournamentApplicationStatus,
			tournamentApplicationGames: $tournamentApplicationGames,
			${mutationArguments}
      ) {
        id
      }
    }
  `;
};

export const mapAddMutation = (form: { [key: string]: any }, game: string, lang: CraftForm['lang'] = 'en') => {
  const mutationData: { [key: string]: any } = {};

  for (const [key, value] of Object.entries(form)) {
    mutationData[key] = value;
  }

  mutationData['title'] = form.eventName;
  mutationData['slug'] = form.eventName;
  mutationData['tournamentApplicationStatus'] = 'unread';
  mutationData['tournamentApplicationGames'] = game;
  mutationData['applicationLanguage'] = lang || 'en';

  return mutationData;
};

export const defineApplicationRegion = (counter: Record<Exclude<Region, 'paneuropean'>, number>): Region => {
  let definedCounter = 0;
  for (const prop in counter) {
    if (counter[prop as keyof typeof counter]) {
      definedCounter += 1;
    }
  }

  if (definedCounter > 1) {
    return 'paneuropean';
  } else if (counter.franceBenelux > 0) {
    return 'franceBenelux';
  } else if (counter.centralEurope > 0) {
    return 'centralEurope';
  } else if (counter.easternEurope > 0) {
    return 'easternEurope';
  } else if (counter.russiaCis > 0) {
    return 'russiaCis';
  } else if (counter.turkey > 0) {
    return 'turkey';
  } else if (counter.iberiaItaly > 0) {
    return 'iberiaItaly';
  } else if (counter.ukNordics > 0) {
    return 'ukNordics';
  } else if (counter.middleEastNorthAfrica > 0) {
    return 'middleEastNorthAfrica';
  }
  return 'paneuropean';
};

export const regionCounter = (form: { [key: string]: any }): Record<Exclude<Region, 'paneuropean'>, number> => {
  const counter: Record<Exclude<Region, 'paneuropean'>, number> = {
    franceBenelux: 0,
    centralEurope: 0,
    easternEurope: 0,
    russiaCis: 0,
    turkey: 0,
    iberiaItaly: 0,
    ukNordics: 0,
    middleEastNorthAfrica: 0,
  };

  if (form.countries) {
    form.countries.forEach((country: string) => {
      if (applicationsFranceBenelux.includes(country)) {
        counter.franceBenelux += 1;
      } else if (applicationsCentralEurope.includes(country)) {
        counter.centralEurope += 1;
      } else if (applicationsEasternEurope.includes(country)) {
        counter.easternEurope += 1;
      } else if (applicationsRussiaCis.includes(country)) {
        counter.russiaCis += 1;
      } else if (applicationTurkey.includes(country)) {
        counter.turkey += 1;
      } else if (applicationIberiaItaly.includes(country)) {
        counter.iberiaItaly += 1;
      } else if (applicationsUkNordics.includes(country)) {
        counter.ukNordics += 1;
      } else if (applicationsMiddleEastNorthAfrica.includes(country)) {
        counter.middleEastNorthAfrica += 1;
      }
    });
  }

  return counter;
};
