import { InputLabel, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { FC, useEffect, useState } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

import { CraftFormElement, CraftFormElementChoice } from '../../../interfaces/form.interface';

const RTMultipleSelect: FC<{
  control: Control<FieldValues>;
  question: CraftFormElement;
  error: any;
}> = ({ control, question, error }) => {
  const [choices, setChoices] = useState<Map<string, CraftFormElementChoice>>(new Map());
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    setChoices(new Map(question.choices.map(i => [i.value, i])));
    setOptions(question.choices.map(c => c.value));
  }, [question.choices]);

  return (
    <Controller
      control={control}
      name={question.name}
      render={({ field: { onChange, onBlur, value, ref } }: any) => (
        <div className='rt-input-multiselect'>
          <InputLabel>{question.title}</InputLabel>
          <Autocomplete
            options={options.sort()}
            multiple
            value={value}
            onChange={(_, v) => {
              onChange(v.map(c => c));
            }}
            filterSelectedOptions
            onBlur={onBlur}
            getOptionLabel={(option: string) => choices.get(option)?.text || ''}
            renderInput={(params: any) => (
              <TextField
                {...params}
                autoComplete='off'
                error={error}
                helperText={error?.type || question.description}
                variant='outlined'
                required={!value || value?.length === 0}
              />
            )}
          />
        </div>
      )}
    ></Controller>
  );
};

export default RTMultipleSelect;
