// Autriche, Allemagne, Liechtenstein, Suisse
export const applicationsCentralEurope = ['aut', 'deu', 'lie', 'che'];

// Albanie, Bosnie-Herzégovine, Bulgarie, Croatie, Chypre, République tchèque, Estonie, Grèce, Hongrie, Israël, Kosovo, Lettonie, Lituanie, Monténégro, Macédoine du Nord, Roumanie, Serbie, Slovaquie, Pologne, Slovénie, Ukraine, Moldavie
export const applicationsEasternEurope = [
  'alb',
  'bih',
  'bgr',
  'hrv',
  'cyp',
  'cze',
  'est',
  'grc',
  'hun',
  'isr',
  'xkx',
  'lva',
  'lut',
  'mne',
  'mkd',
  'rou',
  'srb',
  'svk',
  'pol',
  'svn',
  'ukr',
  'mda',
];

// France, Belgique, Monaco, Pays-Bas, Luxembourg
export const applicationsFranceBenelux = ['fra', 'bel', 'mco', 'nld', 'lux'];

// Arménie, Azerbaïdjan, Biélorussie, Géorgie, Kazakhstan, Kirghizistan,  Mongolie, Russie, Tadjikistan, Turkménistan, Ouzbékistan
export const applicationsRussiaCis = ['arm', 'aze', 'blr', 'geo', 'kaz', 'kgz', 'mng', 'rus', 'tjk', 'tkm', 'uzb'];

// Turquie
export const applicationTurkey = ['tur'];

// Portugal, Espagne, Andorre, Vatican, Italie, Saint-Marin
export const applicationIberiaItaly = ['and', 'prt', 'esp', 'vat', 'ita', 'smr'];

// Royaume-Uni, Danemark, Finlande, Islande, Norvège, Suède, Irlande, Malte
export const applicationsUkNordics = ['gbr', 'dnk', 'fin', 'isl', 'nor', 'swe', 'irl', 'mlt'];

// Algérie, Bahreïn, Égypte, Irak, Jordanie, Koweït, Liban, Libye, Maroc, Oman, Qatar, Arabie saoudite, Tunisie, Émirats arabes un
export const applicationsMiddleEastNorthAfrica = [
  'alg',
  'bah',
  'egy',
  'ira',
  'jor',
  'kuw',
  'leb',
  'lib',
  'mrc',
  'oma',
  'qat',
  'sab',
  'tun',
  'uae',
  'pal',
];
