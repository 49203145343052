import { useMutation } from '@apollo/client';
import { navigate } from 'gatsby';
import * as QueryString from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import RTFormDialog from '../../components/form/form-dialog';
import RTSelect from '../../components/form/inputs/select';
import RTLayout from '../../components/global/layout/layout';
import SEO from '../../components/global/seo/seo';
import { GAMES } from '../../constants/games';
import { Conditions, CraftForm, CraftFormElement, CraftFormPage } from '../../interfaces/form.interface';
import * as style from './form.module.scss';
import { ADD_MUTATION, defineApplicationRegion, mapAddMutation, regionCounter } from './form.mutation';
import { renderInputs } from './form.service';
import { useStyles } from './form.style';
import { Region } from './type';

const RTForm: FC<{ pageContext: CraftForm }> = ({ pageContext }) => {
  const lang = pageContext.lang;
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageForm, setCurrentPageForm] = useState<CraftFormPage>();
  const [elementX, setElementX] = useState<any>([]);
  const [completedForms, setCompletedForms] = useState<{ [key: string]: any }[]>([]);
  const [formPages, setFormPages] = useState<CraftFormPage[]>([]);
  const [smallTournamentForm, setSmallTournamentForm] = useState<CraftFormPage>();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    trigger,
    register,
  } = useForm();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [broadcastAmount] = useState(0);
  const [broadcastQuestions, setBroadcastQuestions] = useState<any>([]);
  const [readyToBeSentForm, setReadyToBeSentForm] = useState<{ [key: string]: any }>();
  const [isIntro, setIsIntro] = useState(true);

  const allQuestions = pageContext.pages.reduce((acc: any, page) => [...acc, ...page.elements], []);

  const [save_applicationsFranceBenelux_applicationsFranceBenelux_Entry] = useMutation(
    ADD_MUTATION('save_applicationsFranceBenelux_applicationsFranceBenelux_Entry', allQuestions),
  );
  const [save_applicationsPaneuropean_tournamentApplication_Entry] = useMutation(
    ADD_MUTATION('save_applicationsPaneuropean_tournamentApplication_Entry', allQuestions),
  );
  const [save_applicationsTurkey_applicationsTurkey_Entry] = useMutation(
    ADD_MUTATION('save_applicationsTurkey_applicationsTurkey_Entry', allQuestions),
  );
  const [save_applicationsUkNordics_applicationsUkNordics_Entry] = useMutation(
    ADD_MUTATION('save_applicationsUkNordics_applicationsUkNordics_Entry', allQuestions),
  );
  const [save_applicationsRussiaCis_applicationsRussiaCis_Entry] = useMutation(
    ADD_MUTATION('save_applicationsRussiaCis_applicationsRussiaCis_Entry', allQuestions),
  );
  const [save_applicationsCentralEurope_applicationsCentralEurope_Entry] = useMutation(
    ADD_MUTATION('save_applicationsCentralEurope_applicationsCentralEurope_Entry', allQuestions),
  );
  const [save_applicationsEasternEurope_applicationsEasternEurope_Entry] = useMutation(
    ADD_MUTATION('save_applicationsEasternEurope_applicationsEasternEurope_Entry', allQuestions),
  );
  const [save_applicationIberiaItaly_applicationIberiaItaly_Entry] = useMutation(
    ADD_MUTATION('save_applicationIberiaItaly_applicationIberiaItaly_Entry', allQuestions),
  );
  const [save_applicationsMiddleEastNorthAfrica_applicationsMiddleEastNorthAfrica_Entry] = useMutation(
    ADD_MUTATION('save_applicationsMiddleEastNorthAfrica_applicationsMiddleEastNorthAfrica_Entry', allQuestions),
  );

  const onError = (errors: any) => {
    console.log(errors);
  };

  /**
   * Check wether the form should end or not
   **/
  const shouldExit = (conditions: Conditions[], data: { [key: string]: any }) => {
    const operators: any = {
      '===': (a: any, b: any) => a === b,
      '!==': (a: any, b: any) => a !== b,
      '<': (a: number, b: number) => a < b,
      '>': (a: number, b: number) => a > b,
      '<=': (a: number, b: number) => a <= b,
      '>=': (a: number, b: number) => a >= b,
    };

    const conditionArray = conditions?.reduce((acc: boolean[], currentValue) => {
      const orQuestion =
        currentValue?.reduce((accQuestion, c: any) => {
          if (accQuestion === true) {
            return true;
          }
          accQuestion = operators[c.operator](data[c.key], c.value);
          return accQuestion;
        }, false) || false;
      acc = [...acc, orQuestion];
      return acc;
    }, []);
    return conditionArray.filter(c => c === false).length === 0;
  };

  const onSubmit = (data: { [key: string]: any }) => {
    trigger();
    scrollTo(0, 0);
    if (formPages) {
      const form = { ...completedForms, ...data };
      setCompletedForms(form);
      localStorage?.setItem('application-form', JSON.stringify(form));

      const conditions: Conditions[] = eval(pageContext.endingConditions);

      if (shouldExit(conditions, data)) {
        setOpen(true);
      } else {
        const maxLength = formPages.length - 1;
        if (currentPage < maxLength) setCurrentPage(currentPage + 1);
        if (currentPage === maxLength) setReadyToBeSentForm(form);
      }
    }
  };

  const handleClose = (val: boolean) => {
    setOpen(false);
    if (val) {
      navigate(`${lang ? '/' + lang : '/'}`);
    }
  };

  const scrollToTop = (): void => {
    window.scrollTo(0, 0);
  };

  // Create form
  useEffect(() => {
    const smallForm = pageContext.pages.filter(
      (page: { name: string }) => page.name === 'Please fill in your information to receive your licence',
    )[0];
    const basicForm = pageContext.pages.filter(
      (page: { name: string }) => page.name !== 'Please fill in your information to receive your licence',
    );
    setFormPages(basicForm);
    setSmallTournamentForm(smallForm);
  }, [pageContext]);

  // Parse url
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const params = QueryString.parse(location.search);
      if (localStorage) {
        const formData = JSON.parse(localStorage.getItem('application-form') || '{}');
        setCompletedForms(formData);
        for (const [key, value] of Object.entries(formData)) {
          register(key);
          setValue(key, value);
        }
      }
      if (params.game) {
        setValue('game', params.game as string);
      }
    }
  }, []);

  // Listen if the form is completed, format form && redirect user to completed page
  useEffect(() => {
    if (readyToBeSentForm) {
      setIsLoading(true);
      if (Object.keys(readyToBeSentForm).length > 7) {
        const counter = regionCounter(readyToBeSentForm);
        readyToBeSentForm.applicationRegion = defineApplicationRegion(counter);
        const currentLang = JSON.parse(localStorage.getItem('RT_current_lang') || '{}').value;
        const variables = mapAddMutation(readyToBeSentForm, readyToBeSentForm.game, currentLang);

        const successCallback = () => {
          setIsLoading(false);
          localStorage.removeItem('application-form');
          navigate(`${lang ? '/' + lang : ''}/form/completed?type=normal`);
        };
        switch (readyToBeSentForm.applicationRegion as Region) {
          case 'paneuropean' || !readyToBeSentForm.countries:
            save_applicationsPaneuropean_tournamentApplication_Entry({ variables }).then(() => {
              successCallback();
            });
            break;
          case 'franceBenelux':
            save_applicationsFranceBenelux_applicationsFranceBenelux_Entry({ variables }).then(() => {
              successCallback();
            });
            break;
          case 'easternEurope':
            save_applicationsEasternEurope_applicationsEasternEurope_Entry({ variables }).then(() => {
              successCallback();
            });
            break;
          case 'centralEurope':
            save_applicationsCentralEurope_applicationsCentralEurope_Entry({ variables }).then(() => {
              successCallback();
            });
            break;
          case 'russiaCis':
            save_applicationsRussiaCis_applicationsRussiaCis_Entry({ variables }).then(() => {
              successCallback();
            });
            break;
          case 'turkey':
            save_applicationsTurkey_applicationsTurkey_Entry({ variables }).then(() => {
              successCallback();
            });
            break;
          case 'iberiaItaly':
            save_applicationIberiaItaly_applicationIberiaItaly_Entry({ variables }).then(() => {
              successCallback();
            });
            break;
          case 'ukNordics':
            save_applicationsUkNordics_applicationsUkNordics_Entry({ variables }).then(() => {
              successCallback();
            });
            break;
          case 'middleEastNorthAfrica':
            save_applicationsMiddleEastNorthAfrica_applicationsMiddleEastNorthAfrica_Entry({ variables }).then(() => {
              successCallback();
            });
            break;
        }
      } else {
        setIsLoading(false);
        navigate(`${lang ? '/' + lang : ''}/form/completed?type=small`);
      }
    }
  }, [readyToBeSentForm]);

  useEffect(() => {
    if (formPages) {
      setCurrentPageForm(formPages[currentPage]);
    }
  }, [formPages, currentPage]);

  useEffect(() => {
    if (broadcastAmount === 0 && currentPageForm) {
      return;
    }

    if (broadcastAmount === 1 && currentPageForm) {
      setElementX(currentPageForm.elements.filter((el: any) => el.group === 'broadcast'));
    }

    if (currentPageForm) {
      setBroadcastQuestions([
        ...broadcastQuestions,
        elementX.reduce((results: any, question: any) => {
          question.name = `${question.name}-${broadcastAmount}`;
          return [...results, question];
        }, []),
      ]);
    }
  }, [broadcastAmount]);

  const gameQuestion: CraftFormElement = {
    type: 'radiogroup',
    name: 'game',
    title: pageContext?.uiElements?.gameFieldTitle || 'Game',
    isRequired: 'true',
    description: pageContext?.uiElements?.gameFieldDescription || 'Choose the game you are applying for',
    choices: GAMES,
  };

  return (
    <RTLayout
      menuText={pageContext?.uiElements?.menuTxt[0]}
      footerText={pageContext?.uiElements?.footerTxt[0]}
      langModalTranslations={{ title: pageContext?.uiElements?.languageModalTitle, subtitle: pageContext?.uiElements?.languageModalText }}
      cookie={{
        cookieContent: pageContext?.uiElements.cookieContent,
        cookieButtonAccept: pageContext?.uiElements.cookieButtonAccept,
        cookieButtonReject: pageContext?.uiElements.cookieButtonReject,
      }}
    >
      <SEO title={'Licence request'} description='Tournament form' />
      <RTFormDialog
        open={open}
        onClose={handleClose}
        dialogSentence={pageContext?.uiElements?.formTxt[0].dialogSentence}
        nextTxt={pageContext?.uiElements?.controlButtonTxt[0].next}
      />
      <div className={style.formContainer}>
        <div className={style.form}>
          <h1 className={style.formTitle}>{currentPageForm?.name}</h1>
          {isIntro ? (
            <>
              <div
                className={`wysiwyg`}
                dangerouslySetInnerHTML={{
                  __html: pageContext.questionnaireIntroduction,
                }}
              />
              <div className={style.buttonWrapper}>
                <button
                  className={style.submitButton}
                  type='button'
                  onClick={() => {
                    setIsIntro(false);
                    scrollToTop();
                  }}
                >
                  / {pageContext?.uiElements?.controlButtonTxt[0].next || 'Next'}
                </button>
              </div>
            </>
          ) : (
            <form onSubmit={handleSubmit(onSubmit, onError)} className={classes.root}>
              {currentPage === 0 && <RTSelect control={control} question={gameQuestion} />}
              {currentPageForm?.elements
                .filter(el => !el.group)
                .map((question, idx) => {
                  return [renderInputs(question, idx, setValue, control, errors, getValues(question.name))];
                })}
              <div className={style.buttonWrapper}>
                <button
                  className={`${style.submitButton} ${style.previousButton} `}
                  type='button'
                  disabled={isLoading}
                  onClick={() => {
                    scrollToTop();
                    currentPage === 0 ? setIsIntro(true) : setCurrentPage(currentPage - 1);
                  }}
                >
                  / {pageContext?.uiElements?.controlButtonTxt[0].previous || 'Previous'}
                </button>
                <button
                  className={style.submitButton}
                  type='submit'
                  disabled={isLoading}
                  onClick={() => currentPage < formPages.length - 1 && scrollToTop()}
                >
                  {currentPage < formPages.length - 1
                    ? `/ ${pageContext?.uiElements?.controlButtonTxt[0].next || 'Next'}`
                    : `/ ${pageContext?.uiElements?.controlButtonTxt[0].submit || 'Submit'}`}
                </button>
              </div>
              <div className={style.pager}>
                {currentPage + 1}/{formPages.length}
              </div>
            </form>
          )}
        </div>
      </div>
    </RTLayout>
  );
};

export default RTForm;
