import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        'margin-bottom': '2.5rem !important',
      },
      '& .MuiFormControlLabel-root': {
        margin: '0.625rem',
      },
      '& .MuiFormLabel-root': {
        fontSize: '1.125rem',
        color: '#fff !important',
        opacity: '30%',
        'font-family': "'Mark SC Offc Pro' !important",
        'margin-bottom': '1rem',
      },
      '& .MuiInput-input': {
        background: 'none',
        fontSize: '1rem',
        color: '#fff',
        'font-family': "'Mark SC Offc Pro' !important",
        'line-height': 'normal',
      },
      '& .MuiInput-underline:after': {
        'border-bottom': '1px solid #fff',
      },
      '& .MuiInput-underline:before': {
        'border-bottom': '1px solid #fff',
        opacity: '30%',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        'font-family': "'Mark SC Offc Pro' !important",
        margin: '8px',
      },
      '& .MuiFormControlLabel-label': {
        'font-family': "'Mark SC Offc Pro' !important",
        fontSize: '1rem',
      },
      '& button > span.MuiIconButton-label > svg': {
        fill: '#fff',
        opacity: 0.5,
      },
      '& .PrivateNotchedOutline-legendLabelled-11 > span': {
        'font-size': '12px',
      },
      '& label + .MuiInput-formControl': {
        padding: '10px',
      },
      '& .MuiRadio-colorSecondary.Mui-checked': {
        color: '#d13639',
      },
    },
  }),
);
