import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { FC } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { CraftFormElement } from '../../../interfaces/form.interface';

const RTRadio: FC<{
  control: Control<FieldValues>;
  question: CraftFormElement;
  value: any;
}> = ({ control, question, value }) => {
  return (
    <Controller
      control={control}
      name={question.name}
      defaultValue=''
      render={({ field: { onChange, onBlur, value } }: any) => (
        <FormControl>
          <FormLabel required={question.isRequired === 'true'} component='legend' style={{ marginBottom: 0 }}>
            {question.title}
          </FormLabel>
          <FormHelperText style={{ marginBottom: '1rem' }}>{question.description}</FormHelperText>
          <RadioGroup name={question.name} value={value} onChange={onChange} onBlur={onBlur} style={{ marginBottom: '1rem' }}>
            {question.choices.map((choice, idx) => (
              <FormControlLabel
                value={choice.value}
                control={<Radio required={!value || value === ''} />}
                key={`${choice.value}-${idx}`}
                label={choice.text}
                name={choice.text}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};

export default RTRadio;
