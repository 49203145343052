import { Dialog } from '@material-ui/core';
import React, { FC } from 'react';
import Close from '../../assets/icon/Close';
import GlobalButton from '../global/global-button/global-button';
import * as style from './form-dialog.module.scss';

const RTFormDialog: FC<{
  onClose: (value: boolean) => any;
  open: boolean;
  dialogSentence: string;
  nextTxt: string;
}> = ({ onClose, open, dialogSentence, nextTxt }) => {
  return (
    <Dialog open={open}>
      <div className={style.dialogContainer}>
        <h3 className={style.dialogTitle}>{dialogSentence}</h3>
        <div className={style.dialogButtonWrapper} onClick={() => onClose(true)}>
          <GlobalButton text={nextTxt} />
        </div>
        <button className={style.dialogClose} onClick={() => onClose(false)}>
          <Close />
        </button>
      </div>
    </Dialog>
  );
};

export default RTFormDialog;
